<template>
	<a-modal
		v-model="show"
		title="计划分类设置"
		:width="600"
		:footer="null"
		@cancel="
			() => {
				$emit('success');
				show = false;
			}
		"
		centered
		destroyOnClose
	>
		<a-table :scroll="{ x: '100%',y:'300px' }" :style="{ background: '#FFF' }" size="small" :columns="keys" ref="list" :data-source="category" :bordered="false" row-key="categoryId" :pagination="false">
			<!--名称-->
			<template slot-scope="text, row, index" slot="name">
				<a-input v-model="row.name" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input>
				<span v-else>{{ row.name }}</span>
			</template>
			<!--排序-->
			<template slot-scope="text, row, index" slot="sort">
				<a-input-number v-model="row.sort" align="center" v-if="row.edit" @pressEnter="confirmRow(row, index)"></a-input-number>
				<span v-else>{{ row.sort }}</span>
			</template>
			<!--操作-->
			<template slot-scope="text, row, index" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="编辑" v-if="!row.edit">
						<a-button icon="edit" @click="editRow(row, index)"></a-button>
					</a-tooltip>
					<a-tooltip title="保存" v-else>
						<a-button icon="check" @click="confirmRow(row, index)"></a-button>
					</a-tooltip>
					<a-tooltip title="取消" v-if="row.edit"><a-button icon="close" @click="delRow(row, index)"></a-button></a-tooltip>
					<a-tooltip title="删除" v-else><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
				</a-button-group>
			</template>
			<template slot="footer">
				<div class="footer">
					<a-button type="primary" @click="addRow" size="small" icon="plus">添加新分类</a-button>
				</div>
			</template>
		</a-table>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
	data() {
		return {
			show: false,
			loading: false,
			category: [],
			initname: '',
			keys: [
				{ title: '分类名称', scopedSlots: { customRender: 'name' }, width: 200, resizable: true },
				{ title: '排序', scopedSlots: { customRender: 'sort' }, align: 'center', width: 80, resizable: true, sorter: (a, b) => a.sort - b.sort },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},
	methods: {
		...apiUtil,
		// 获取数据
		getCategory() {
			this.http.get('/platform/work-plan/category', { organId: apiUtil.getDefaultOrganId() }).then(res => {
				if (res.code == 200) {
					res.data.forEach(item => (item.edit = false));
					this.category = res.data;
				}
			});
		},
		addRow() {
			const item = {
				categoryId: '',
				name: '',
				organId: this.organId,
				sort: 0,
				edit: true
			};
			this.category.push(item);
		},
		delRow(row, index) {
			if (!row.categoryId) {
				this.category.splice(index, 1);
				return;
			}
			if (row.edit) {
				row.edit = false;
				row.name = this.initname;
				return;
			}
			utils.confirm('您确认要删除此分类吗?').then(() => {
				utils.showSpin();
				request.delete('/platform/work-plan/category/del/' + row.categoryId).then(ret => {
					this.getCategory();
				});
			});
		},
		editRow(row, index) {
			row.edit = true;
			this.initname = row.name;
		},
		confirmRow(row, index) {
			utils.showSpin();
			request.post('/platform/work-plan/category/save', row).then(ret => {
				this.getCategory();
			});
		},
		_show() {
			this.show = true;
			this.getCategory();
		},
		_close() {
			console.log(1);
			this.show = false;
		}
	},
	props: {
		organId: {
			type: Number | String,
			default: () => {
				return apiUtil.getOrganId();
			}
		}
	}
};
</script>
<style>
.footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
</style>
